import React from "react";
//STYLES
import styled from "styled-components";
import { theme } from "../../../../utils/theme";
import { Ikonka } from "../../../Icons/Icon";

//DATA
import { Heading } from "../../../../utils/utilsstyles";
import Slider from "../../../Slider/Slider";
//COMPONENTS
//FUNCTIONS
import { useLanguage } from "../../../../services/LanguageContext";

const Wrapper = styled.div`
  h3 {
    margin-bottom: 5px;
  }
`;

const SettingsWrapper = styled.div`
  display: grid;
  row-gap: 2rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

const Empty = styled.div`
  display: flex;
  height: 390px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  justify-content: center;

  svg {
    height: 100px;
    margin-bottom: 1rem;
    fill: ${theme.color.base1};
  }

  p {
    font-size: 18px;
    line-height: 1.3;
  }
`;

function EcoMode({ device }) {
  const { translate } = useLanguage();

  return (
    <Wrapper>
      <Heading>
        <h3>{translate("eco_header")}</h3>
      </Heading>
      <SettingsWrapper>
        <Empty>
          <Ikonka name="empty" />
          <p>{translate("frost_empty")}</p>
        </Empty>
      </SettingsWrapper>
    </Wrapper>
  );
}

export default EcoMode;
