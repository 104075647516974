import React, { useState } from "react";
//STYLES
import styled from "styled-components";
import { theme } from "../../../../utils/theme";
//Utils
import { useLanguage } from "../../../../services/LanguageContext";
//COMPONENTS
import Switch from "../../../Switch/Switch";
import ButtonRounded from "../../../Button/ButtonRounded";
import Modal from "../../../Modals/Modal";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 180px auto 40px 40px;
  align-items: center;
  padding: 1rem 0;
  column-gap: 2rem;
  border-bottom: 1px solid lightgrey;

  h1 {
    font-weight: 500;
    font-size: 28px;
    &.on {
      color: ${theme.color.base1};
    }
  }

  &.losowo {
    grid-template-columns: 220px auto 1fr 40px;
  }
`;
const Once = styled.div`
  min-width: 200px;
  margin-bottom: 0.7rem;
  font-weight: 700;
`;
const Days = styled.div`
  display: grid;
  min-width: 200px;
  max-width: 250px;
  grid-template-columns: repeat(7, 1fr);
  column-gap: 3px;
  margin-bottom: 0.7rem;
  p {
    text-transform: uppercase;
    font-weight: 700;
  }

  &.cyklicznie {
    min-width: 220px;
  }
`;

const TimeAndSettings = styled.div`
  > div {
    margin-top: 0.5rem;
    display: grid;
    grid-template-columns: 40px 40px;
    column-gap: 1rem;
  }
`;

const Cyklicznie = styled.div`
  p {
    margin-bottom: 0.5rem;
  }
`;

function SettingTime({
  isOn,
  isRelayOn,
  time,
  days,
  startTime,
  endTime,
  finishTime,
  onDurationTime,
  offDurationTime,
  editClickedHandler,
  entry,
  onRemoved,
  onOnOffChanged,
  isReadOnly,
}) {
  const { translate } = useLanguage();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const toggleDeleteModal = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const handleDeleted = () => {
    setIsDeleteModalOpen(false);
    onRemoved(entry);
  };

  // Use isOn if isTimerEnabled is not provided
  const effectiveIsRelayOn = isRelayOn !== undefined ? isRelayOn : isOn;

  return (
    <Wrapper className={endTime && "losowo"}>
      {time && (
        <TimeAndSettings>
          <h1 className={isOn ? "on" : ""}>
            {finishTime ? `${time} - ${finishTime}` : `${time}`}
          </h1>
          {/* Switch uses effectiveIsTimerEnabled */}
          <Switch
            isOn={isOn}
            onChange={onOnOffChanged}
            isReadOnly={isReadOnly}
          />
        </TimeAndSettings>
      )}

      <div>
        {endTime ? (
          ""
        ) : days.length > 0 ? (
          <Days>
            {days.map((day, index) => (
              <p key={index}>{day}</p>
            ))}
          </Days>
        ) : (
          <Once>{translate("once")}</Once>
        )}

        {(onDurationTime && !endTime && (
          <Cyklicznie>
            <p>
              {translate("openTime", "Open time:")} <b>{onDurationTime}</b>
            </p>
            <p>
              {translate("closeTime", "Close time:")} <b>{offDurationTime}</b>
            </p>
          </Cyklicznie>
        )) ||
          (isReadOnly ? (
            <p>{translate("roller_check_in_app")}</p>
          ) : (
            <p>
              {translate("toggler", "Toggler:")}{" "}
              <b>{effectiveIsRelayOn ? "ON" : "OFF"}</b>
            </p>
          ))}
      </div>

      {isReadOnly ? (
        <div></div>
      ) : (
        <ButtonRounded icon="settings" onClick={editClickedHandler} />
      )}

      <ButtonRounded icon="remove" onClick={toggleDeleteModal} />

      {isDeleteModalOpen && (
        <Modal
          title={translate("remove")}
          intro={translate("remove_confirm")}
          onClose={toggleDeleteModal}
          confirmText={translate("remove")}
          onConfirm={handleDeleted}
        ></Modal>
      )}
    </Wrapper>
  );
}

export default SettingTime;
