import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { MutatingDots } from "react-loader-spinner";
//STYLES
import styled, { keyframes, css } from "styled-components";
import { theme } from "../../utils/theme";
import { motion } from "framer-motion";
//SERVICES
import { useLanguage } from "../../services/LanguageContext";
// import SignalRService from "../../services/SignalRService";
import { useSignalR } from "../../services/SignalRProvider";
import { loadDeviceDetails } from "../../services/ApiService";
import Device from "../../services/Device";

//COMPONENTS
import RouteLayout from "../../layout/RouteLayout";
import TrybPracy from "./Roller/RollerWorkingMode";
import { FullCardWrapper, Heading, Loader } from "../../utils/utilsstyles";
import { Ikonka } from "../Icons/Icon";
import CircIconLabeled from "../Icons/CircIconLabeled";
import PrimaryDeviceSettings from "./PrimaryDeviceSettings";
import DiagnosticDeviceInformation from "./DiagnosticDeviceInformation";
import Dropdown from "../Forms/Dropdown";
import Input from "../Forms/Input";
import CircIcon from "../Icons/CircIcon";
import ButtonRounded from "../Button/Button";
import Button from "../Button/Button";
import Slider from "../../components/Slider/Slider";

const Wrapper = styled.div`
  z-index: 10;
  background-color: ${theme.color.secondLight};
`;

const AllSettingsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
`;

const CurrentSettingsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 6rem;
  row-gap: 1.5rem;
  margin-bottom: 2rem;
  margin-top: 1.5rem;

  > div {
    display: grid;
    grid-template-columns: 2fr 3fr;

    > div:nth-child(2) {
      min-width: 200px;
      justify-self: end;

      > div {
        min-width: 200px;
      }
    }
  }
`;

const SettingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding-bottom: 2rem;
  margin-top: 1.5rem;

  /* button {
    align-self: center;
    background-color: transparent;
    padding: 0;
    width: 100px;
    transition: all 0.6s ease-in-out;
    margin-top: -1rem;

    svg {
      fill: ${theme.color.base1};
      width: 100px;
      height: 100px;
      transition: all 0.6s ease-in-out;
    }

    h6 {
      color: ${theme.color.base1};
      text-transform: uppercase;
      transition: all 0.6s ease-in-out;
    }

    &:hover {
      background-color: transparent;

      svg {
        fill: ${theme.color.prim1};
        background-color: transparent;
      }

      h6 {
        color: ${theme.color.prim1};
        background-color: transparent;
      }

      div {
        background-color: transparent;
      }
    }
  } */
`;

const ButtonWrapper = styled.div`
  display: grid;
  row-gap: 1rem;
`;

const OneSetting = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Roller = styled.div`
  //border: 6px solid ${theme.color.base1};
  width: 200px;
  height: 200px;
  border-radius: 100px;
  display: grid;
  grid-template-columns: 150px;
  grid-template-rows: 1fr 24px 1fr;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
  transition: all 0.6s ease-in-out;

  justify-self: center;
  align-self: center;
  //margin-top: 2rem;
  box-shadow: 4px 4px 17px 6px rgba(0, 0, 0, 0.1),
    -4px -4px 8px 0 rgba(255, 255, 255, 0.5);

  /* &:hover {
    border: 6px solid ${theme.color.prim1};
    h6 {
      color: ${theme.color.prim1};
      transition: all 0.6s ease-in-out;
    }
    svg {
      fill: ${theme.color.prim1};
      transition: all 0.6s ease-in-out;
    }
  } */

  h6 {
    font-size: 36px;
    font-weight: 500;
    margin: 0;
    margin-top: -2.3rem;
    padding: 0;
    color: ${theme.color.base1};
  }
`;

const RollerButton = styled.div`
  border: 2px solid ${theme.color.base1};
  border-radius: 100%;
  padding: 10px;
  margin: 1rem auto;
  //margin-top: -5.3rem;
  //margin-bottom: 3rem;
  cursor: pointer;
  transition: all 0.6s ease-in-out;
  svg {
    width: 24px;
    height: auto;
    fill: ${theme.color.base1};
    transition: all 0.6s ease-in-out;
  }

  &:hover {
    background-color: ${theme.color.base6};
    border-radius: 100%;
    border-color: ${theme.color.prim1};
    transition: all 0.6s ease-in-out;
    svg {
      fill: ${theme.color.prim1};
      transition: all 0.6s ease-in-out;
    }
  }
`;

const UpDownButton = styled.div`
  cursor: ${(props) => (props.active ? "defoult" : "pointer")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //gap: 0.5rem;
  gap: ${(props) => (props.active ? "0" : "0.2rem")};
  transition: all 0.6s ease-in-out;

  p {
    font-size: 12px;
    text-transform: uppercase;
    opacity: 0.4;
    font-weight: 600;
    transition: all 0.6s ease-in-out;
  }

  svg {
    width: 24px;
    height: auto;
    fill: ${(props) => (props.active ? "${theme.color.base1}" : "#6a6a6a")};
    transition: all 0.6s ease-in-out;
  }

  &:hover {
    p {
      color: ${theme.color.base1};
      opacity: 1;
      transition: all 0.6s ease-in-out;
    }
    svg {
      fill: ${theme.color.base1};
      transition: all 0.3s ease-in-out;
    }
  }
`;

// Animation variants for pulsing arrows
const arrowVariants = {
  hidden: { opacity: 0.2, y: 0 },
  visible: {
    margin: "-6px 0",
    fill: theme.color.base1,
    opacity: 1,
    y: [0, 5, 0], // Pulsing effect
    transition: {
      margin: "-6px 0",
      duration: 0.6,
      repeat: Infinity,
      repeatDelay: 0.3,
      ease: "easeInOut",
    },
  },
};

function RollerSettings({ onClick, title, type }) {
  const { id } = useParams(); // Get the id from the URL
  const [device, setDevice] = useState(null);
  const [loading, setLoading] = useState(true);

  const { translate } = useLanguage();
  const signalRService = useSignalR();

  // Fetch the initial device details
  useEffect(() => {
    const fetchDeviceDetails = async () => {
      const fetchedDeviceDetails = await loadDeviceDetails(id); // Use the id from the URL
      console.log("Relay details", fetchedDeviceDetails);
      setDevice(new Device(fetchedDeviceDetails));
      setLoading(false);
    };

    fetchDeviceDetails();
  }, [id]);

  // Register to SignalRService when the component mounts and unregister when it unmounts
  useEffect(() => {
    const handleUpdateDeviceStatus = (updatedDevice) => {
      // console.log("Update recieved from SignalR");

      if (updatedDevice.id === id) {
        console.log("Update recieved from SignalR");
        console.log(updatedDevice);

        const tempDevice = new Device(updatedDevice);
        setDevice(tempDevice);

        if (tempDevice.getStatusValue("control") === "stop") {
          handlePause();
        }
      }
    };

    signalRService.subscribe("UpdateDeviceStatus", handleUpdateDeviceStatus);

    return () => {
      signalRService.unsubscribe(
        "UpdateDeviceStatus",
        handleUpdateDeviceStatus
      );
    };
  }, [id]);

  const [isRunning, setIsRunning] = useState(false);
  const [direction, setDirection] = useState("");

  const handleOpen = () => {
    setIsRunning(true);
    setDirection("up");
  };

  const handleClose = () => {
    setIsRunning(true);
    setDirection("down");
  };

  const handlePause = () => {
    setIsRunning(false);
    setDirection("");
  };

  const handleUpClick = () => {
    device.setOnDeviceByParams("control", "open");
  };

  const handlePauseClick = () => {
    device.setOnDeviceByParams("control", "stop");
  };

  const handleDownClick = () => {
    device.setOnDeviceByParams("control", "close");
  };

  if (loading) {
    return (
      <Loader>
        <MutatingDots
          visible={true}
          height="100"
          width="100"
          color={theme.color.base1}
          secondaryColor={theme.color.base1}
          radius="12.5"
          ariaLabel="mutating-dots-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </Loader>
    ); // Or any other loading indicator
  }

  const switch_type = "switch_type";
  const percent_control = "percent_control";
  const quick_calibration_1 = "quick_calibration_1";

  return (
    <Wrapper>
      <RouteLayout
        onClick="/"
        title={device.name}
        type={translate("deviceRelay")}
      />

      <AllSettingsWrapper>
        <TrybPracy device={device}></TrybPracy>
        <FullCardWrapper>
          <Heading>
            <h3>{translate("roller_header")}</h3>
          </Heading>
          <SettingsWrapper>
            <Roller>
              {/* Open Button */}
              <UpDownButton
                active={direction === "up"}
                onClick={() => {
                  handleOpen();
                  handleUpClick();
                }}
              >
                {direction === "up" ? (
                  <>
                    <motion.div
                      variants={arrowVariants}
                      initial="hidden"
                      animate="visible"
                    >
                      <Ikonka name="up" />
                    </motion.div>
                    <motion.div
                      variants={arrowVariants}
                      initial="hidden"
                      animate="visible"
                      transition={{ delay: 0.2 }}
                    >
                      <Ikonka name="up" />
                    </motion.div>
                    <motion.div
                      variants={arrowVariants}
                      initial="hidden"
                      animate="visible"
                      transition={{ delay: 0.4 }}
                    >
                      <Ikonka name="up" />
                    </motion.div>
                  </>
                ) : (
                  <Ikonka name="up" />
                )}
                <p>{direction === "up" ? "" : translate("roller_open")}</p>
              </UpDownButton>

              {/* Pause Button */}
              <RollerButton
                active={!isRunning}
                onClick={() => {
                  handlePause();
                  handlePauseClick();
                }}
              >
                <Ikonka name="paus" />
              </RollerButton>

              {/* Down Button with Framer Motion animation */}
              <UpDownButton
                active={direction === "down"}
                onClick={() => {
                  handleClose();
                  handleDownClick();
                }}
              >
                <p>{direction === "down" ? "" : translate("roller_close")}</p>
                {direction === "down" ? (
                  <>
                    <motion.div
                      variants={arrowVariants}
                      initial="hidden"
                      animate="visible"
                    >
                      <Ikonka name="down" />
                    </motion.div>
                    <motion.div
                      variants={arrowVariants}
                      initial="hidden"
                      animate="visible"
                      transition={{ delay: 0.2 }}
                    >
                      <Ikonka name="down" />
                    </motion.div>
                    <motion.div
                      variants={arrowVariants}
                      initial="hidden"
                      animate="visible"
                      transition={{ delay: 0.4 }}
                    >
                      <Ikonka name="down" />
                    </motion.div>
                  </>
                ) : (
                  <Ikonka name="down" />
                )}
              </UpDownButton>
            </Roller>

            <Slider
              icon="percent"
              label={translate("roller_percent_control")}
              value={device.getStatusValue(percent_control)}
              min={device.getFromRange(percent_control)}
              max={device.getToRange(percent_control)}
              step={device.getStepRange(percent_control)}
              unit=" "
              onChange={(value) => {
                console.log(
                  "percent_control changed to value:" + percent_control
                );
                device.setOnDeviceByParams(percent_control, value);
              }}
            />
          </SettingsWrapper>

          <Heading>
            <h3>{translate("current_settings")}</h3>
          </Heading>
          <CurrentSettingsWrapper>
            {/* {
              //Sterowanie silnikiem
            }
            {device.checkIfPropertyExists("control_back") && (
              <OneSetting>
                <CircIconLabeled
                  name="settings"
                  label={translate("control_back")}
                />
                <Dropdown
                  className="minWidth"
                  dropdown={device.getEnumValues("control_back", translate)}
                  defaultValue={device.getSelectedEnum(
                    "control_back",
                    translate
                  )}
                  handleChange={(selectedItem) => {
                    device.setOnDeviceByParams(
                      "control_back",
                      selectedItem.value
                    );
                  }}
                />
              </OneSetting>
            )} */}

            {/* {
              //Szybka kalibracja
            }
            {device.checkIfPropertyExists("switch_type") && (
              <OneSetting>
                <CircIconLabeled
                  name="settings"
                  label={translate("relay_switch_type")}
                />
                <Dropdown
                  className="minWidth"
                  dropdown={device.getEnumValues("switch_type", translate)}
                  defaultValue={device.getSelectedEnum(
                    "switch_type",
                    translate
                  )}
                  handleChange={(selectedItem) => {
                    device.setOnDeviceByParams(
                      "switch_type",
                      selectedItem.value
                    );
                  }}
                />
              </OneSetting>
            )} */}

            {
              //Szybka kalibracja
            }
            {device.checkIfPropertyExists("quick_calibration_1") && (
              <Slider
                label={translate("roller_quick_calibration")}
                icon="settings"
                value={device.getStatusValue(quick_calibration_1)}
                min={device.getFromRange(quick_calibration_1)}
                max={device.getToRange(quick_calibration_1)}
                step={device.getStepRange(quick_calibration_1)}
                onChange={(value) => {
                  console.log(
                    "quick_calibration_1 temp changed to value:" + value
                  );
                  device.setOnDeviceByParams(quick_calibration_1, value);
                }}
                unit=" s"
              />
            )}
          </CurrentSettingsWrapper>
          <PrimaryDeviceSettings device={device} name={device.name} />
          <DiagnosticDeviceInformation device={device} />
        </FullCardWrapper>
      </AllSettingsWrapper>
    </Wrapper>
  );
}

export default RollerSettings;
