import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import styled, { css } from "styled-components";
import { theme } from "../../utils/theme";

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  &.labeled {
    display: grid;
    grid-template-columns: auto auto;
    display: flex;
    align-items: center;
    column-gap: 1rem;
  }

  p {
    &[data-isOn="true"] {
      color: ${theme.color.base1};
      font-weight: 600;
    }
  }
`;

const SwitchButton = styled.div`
  width: 54px;
  height: 30px;
  background-color: ${theme.color.sec6};
  display: flex;
  justify-content: flex-start;
  border-radius: 50px;
  padding: 4px;
  box-shadow: inset 2px 2px 8px 0px rgba(66, 68, 90, 0.2);
  cursor: pointer;
  transition: all 0.4s ease-in-out;

  .handle {
    width: 22px;
    height: 22px;
    background-color: ${theme.color.base9};
    border-radius: 40px;
    box-shadow: 0px 0px 3px 1px rgba(66, 68, 90, 0.2);

    &[data-isOn="true"] {
      background-color: ${theme.color.base1};
    }
  }

  &[data-isOn="true"] {
    justify-content: flex-end;
    background-color: ${theme.color.base6};
  }

  &[datatype="disabled"] {
    background-color: ${theme.color.sec5};
    cursor: default;
    justify-content: unsafe;

    .handle {
      background-color: ${theme.color.sec6};
    }
  }
`;

const Switch = ({
  isOn: initialIsOn = false,
  onChange,
  label,
  labelOff,
  isReadOnly = false,
}) => {
  const [isOn, setIsOn] = useState(initialIsOn);

  useEffect(() => {
    setIsOn(initialIsOn);
  }, [initialIsOn]);

  const toggleSwitch = () => {
    setIsOn((prevIsOn) => {
      const newIsOn = !prevIsOn;
      onChange?.(newIsOn); // Using optional chaining to call onChange only if it is provided
      return newIsOn;
    });
  };

  return (
    <Wrapper className={label ? "labeled" : ""}>
      <SwitchButton
        data-isOn={isOn}
        onClick={isReadOnly ? () => {} : toggleSwitch}
      >
        <motion.div
          className="handle"
          data-isOn={isOn}
          layout
          transition={spring}
        />
      </SwitchButton>
      <p data-isOn={isOn}>{isOn ? label : labelOff}</p>
    </Wrapper>
  );
};

export default Switch;

const spring = {
  type: "spring",
  stiffness: 700,
  damping: 30,
};
