import React, { useEffect, useState } from "react";
//DATA
import axios from "axios";
import termostat from "../../../assets/przekaznik.png";
//COMPONENTS
import OnOffButton from "../../Button/OnOffButton";
import Button from "../../Button/Button";
import CardWrapper from "./CardElements/CardWrapper";
import CardHeader from "./CardElements/CardHeader";
import InfoLine from "./CardElements/InfoLine";
import CardFastSettingsWrapper from "./CardElements/CardFastSettingsWrapper";
import { setOnDevice } from "../../../services/ApiService";
import CardOnOffButton from "./CardElements/CardOnOffButton";

function RollerCard({
  name,
  rooms,
  deviceId,
  statusOnOff,
  type,
  typeId,
  device,
  isOnline,
}) {
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    setIsClicked(statusOnOff);
  }, []);

  return (
    <CardWrapper
      settings={
        <CardFastSettingsWrapper typ={type}>
          {/* <OnOffButton onClick={handleClick} status={statusOnOff} /> */}
          {/* <Button onClick={handleClick}>on/off</Button> */}
        </CardFastSettingsWrapper>
      }
    >
      <CardHeader
        name={name}
        type={type}
        typeId={typeId}
        deviceId={deviceId}
        onCliClick=""
        device={device}
        isOnline={isOnline}
      />
      <InfoLine
        rooms={rooms}
        // onClick={handleClick}
        status={statusOnOff}
        isOnline={isOnline}
      />
      {/* {isOnline && <CardOnOffButton status={statusOnOff} />} */}
    </CardWrapper>
  );
}

export default RollerCard;
