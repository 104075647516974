import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//STYLES
import styled from "styled-components";
import { theme } from "../../../../utils/theme";
//DATA
import { DeviceTypeEnum } from "../../../../utils/Enums/DeviceTypeEnum";

//COMPONENTS
import { Label } from "../../../../utils/typo";
import { Ikonka } from "../../../Icons/Icon";
import TermostatSettings from "../../../Settings/TermostatSettings";
import { useLanguage } from "../../../../services/LanguageContext";
import RelaySettings from "../../../Settings/RelaySettings";
import ControlBoxSettings from "../../../Settings/ControlBoxSettings";

const HeadingWrapper = styled.div`
  display: grid;
  grid-template-columns: 80px 1fr auto;
  column-gap: 1rem;

  div {
    display: flex;
    flex-direction: column;

    h6 {
      margin-top: 0rem;
      font-weight: 500;
      line-height: 1.1;
    }
  }
`;

const OfflineText = styled.p`
  color: orange;
`;

const ButtonMore = styled.a`
  display: flex;
  //border-left: 1px solid ${theme.color.base1};
  //border-bottom: 1px solid ${theme.color.base1};
  cursor: pointer;
  position: absolute;
  width: 60px;
  height: 40px;
  right: 0;
  top: 0;
  border-radius: 0 10px 0px 25px;
  z-index: 1;
  justify-content: center;
  justify-items: center;
  align-items: center;
  transition: all 0.4s ease-in-out;

  &:hover {
    svg {
      fill: ${theme.color.sec6};
    }
  }

  svg {
    fill: ${theme.color.base1};
    transition: all 0.4s ease-in-out;
    margin-left: 4px;
    height: 28px;
  }
`;

const Image = styled.div`
  // Tutaj można dodać dowolny styl dla obrazka
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  //box-shadow: inset 0px 0px 16px 0px rgba(66, 68, 90, 0.3);
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    //background-color: aqua;
    /* border-radius: 10px;
    box-shadow: inset 0px 0px 18px 0px rgba(68, 90, 66, 0.2),
      inset 0px 0px 4px 0px rgba(66, 68, 90, 0.3); */
  }
`;

const ClickableLabel = styled(Label)`
  cursor: pointer;
`;

const ClickableH6 = styled.h6`
  cursor: pointer;
`;

function CardHeader({ name, type, device, typeId, isOnline, history }) {
  const { translate } = useLanguage();
  const navigate = useNavigate();

  const onClickHandler = () => {
    switch (device.deviceType) {
      case DeviceTypeEnum.EngoThermostat:
        navigate(`/TermostatSettings/${device.id}`);
        break;
      case DeviceTypeEnum.EngoRelay:
        navigate(`/RelaySettings/${device.id}`);
        break;
      case DeviceTypeEnum.EngoUnderfloor:
        navigate(`/ControlBoxSettings/${device.id}`);
        break;
      case DeviceTypeEnum.EngoRoller:
        navigate(`/RollerSettings/${device.id}`);
        break;
    }
  };

  return (
    // <> </>
    <HeadingWrapper>
      <Image onClick={onClickHandler}>
        <img src={device.icon} alt={name} />
      </Image>
      <div>
        <ClickableLabel onClick={onClickHandler}>{type}</ClickableLabel>
        {!isOnline && (
          <OfflineText>
            {translate("deviceOffline") || "Poza siecią"}
          </OfflineText>
        )}
        <ClickableH6 onClick={onClickHandler}>{name}</ClickableH6>
      </div>
      {isOnline && typeId !== 3 && (
        <div>
          <ButtonMore onClick={onClickHandler}>
            <Ikonka name="settings" />
          </ButtonMore>
        </div>
      )}
    </HeadingWrapper>
  );
}

export default CardHeader;
