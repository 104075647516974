import { setOnDeviceByParams } from "./ApiService";

class Device {
  constructor(deviceConfig) {
    Object.assign(this, deviceConfig);
  }

  getStatusValue = (propertyToCheck) => {
    if (!this.statusList) {
      return 0; // Handle the case where deviceToCheck is undefined or statusList is not present
    }

    let propertyValue = 0;

    for (const item of this.statusList) {
      if (item.code === propertyToCheck) {
        propertyValue = item.value;
        break;
      }
    }

    return propertyValue;
  };

  getStatusValueFromProperties = (propertiesToCheck) => {
    if (!this.statusList) {
      return 0; // Handle the case where deviceToCheck is undefined or statusList is not present
    }

    let propertyToCheck = this.findExistingPropertyFromList(propertiesToCheck);

    if (propertyToCheck === null || propertiesToCheck === "") {
      return 0;
    }

    let propertyValue = 0;

    for (const item of this.statusList) {
      if (item.code === propertyToCheck) {
        propertyValue = item.value;
        break;
      }
    }

    return propertyValue;
  };

  findExistingPropertyFromList(properties) {
    return properties.find((prop) => this.checkIfPropertyExists(prop)) || "";
  }

  checkIfPropertyExists = (propertyToCheck) => {
    if (!this || !this.statusList) {
      return false; // Handle the case where deviceToCheck is undefined or statusList is not present
    }

    if (
      this.deviceConfig &&
      this.deviceConfig.hiddenProperties.includes(propertyToCheck)
    ) {
      return false; // Handle the case where deviceToCheck is undefined or statusList is not present
    }

    return this.statusList.some((item) => item.code === propertyToCheck);
  };

  checkIfEnumOneOf = (enumTypeToCheck, possibleValues) => {
    let enumValue = this.getStatusValue(enumTypeToCheck);
    return possibleValues.includes(enumValue);
  };

  getStatusValueInTempScale(propertyToCheck) {
    const fromValue = this.getStatusValue(propertyToCheck);
    return this.convertToTempScale(fromValue);
  }

  getFromRange(propertyToCheck) {
    if (!this.deviceConfig.propertiesRanges) {
      return 0;
    }

    const property = this.deviceConfig.propertiesRanges[propertyToCheck];
    const fromValue = property ? property.from : 0;

    return fromValue;
  }

  getFromRangeInTempScale(propertyToCheck) {
    const fromValue = this.getFromRange(propertyToCheck);
    return this.convertToTempScale(fromValue);
  }

  getToRange(propertyToCheck) {
    if (!this.deviceConfig.propertiesRanges) {
      return 0;
    }

    const property = this.deviceConfig.propertiesRanges[propertyToCheck];
    const toValue = property ? property.to : 0;

    return toValue;
  }

  getToRangeInTempScale(propertyToCheck) {
    const toValue = this.getToRange(propertyToCheck);
    return this.convertToTempScale(toValue);
  }

  getStepRange(propertyToCheck) {
    if (!this.deviceConfig.propertiesRanges) {
      return 0;
    }

    const property = this.deviceConfig.propertiesRanges[propertyToCheck];
    const stepValue = property ? property.step : 0;

    return stepValue;
  }

  getStepRangeInTempScale(propertyToCheck) {
    const stepValue = this.getStepRange(propertyToCheck);
    return this.convertToTempScale(stepValue);
  }

  setOnDeviceByParams(command, value) {
    console.log(command + " value: " + value);

    setOnDeviceByParams(this.id, command, value)
      .then((response) => {
        // this.statusList[command] = value;
        console.log("Data posted successfully");
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      });
  }

  setOnDeviceByParamsInTempScale(command, value) {
    const valueInTempScale = this.convertBackFromScaledValue(value);
    console.log(command + " value: " + valueInTempScale);

    setOnDeviceByParams(this.id, command, valueInTempScale)
      .then((response) => {
        console.log("Data posted successfully");
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      });
  }

  getEnumValues(enumName, translate) {
    if (
      !this.deviceConfig ||
      !this.deviceConfig.enumConfig ||
      !this.deviceConfig.enumConfig[enumName]
    ) {
      return [
        {
          value: "",
          label: "",
        },
      ];
    }

    return this.deviceConfig.enumConfig[enumName].possibleValues.map(
      (item) => ({
        value: item.value,
        label: translate(item.translationKey),
      })
    );
  }

  getEnumValuesWithExcluded(enumName, translate, toExclude) {
    const enumValues = this.getEnumValues(enumName, translate);

    return enumValues.filter(({ value }) => !toExclude.includes(value));
  }

  getS1EnumValues(pipe_system, translate) {
    const enumValues = this.getEnumValues("s1_input", translate);

    let targetValues = [];
    switch (pipe_system) {
      case "Pipe2System_CoolAndFan":
      case "Pipe2System_HeatAndFan":
        targetValues = ["nothing_connected", "fan_work_permit"];
        break;
      case "Pipe2System_BothAndFan":
        targetValues = [
          "nothing_connected",
          "heat_cool_switch",
          "heat_cool_auto",
          "fan_work_permit",
        ];
        break;
      case "Pipe2System_UFH":
        targetValues = ["nothing_connected", "ufh_mode"];
        break;
      case "Pipe4System_BothAndFan":
        targetValues = [
          "nothing_connected",
          "heat_cool_switch",
          "heat_cool_auto",
        ];
        break;
      case "Pipe4System_UfhAndCoolWithFan":
        targetValues = [
          "nothing_connected",
          "heat_cool_switch",
          "heat_cool_auto",
          "ufh_mode",
        ];
        break;
      default:
        return [];
    }

    // Filter the enumValues to only those whose 'value' is in our targetValues array
    return enumValues.filter(({ value }) => targetValues.includes(value));
  }

  getSelectedEnum(enumName, translate) {
    const enumValues = this.getEnumValues(enumName, translate);
    const valueOnDevice = this.getStatusValue(enumName);

    // Find the enum in the enumValues array that matches the valueOnDevice
    const selectedEnum = enumValues.find(
      (item) => item.value === valueOnDevice
    );

    // If found, return the whole object or just the label
    return selectedEnum ? selectedEnum : { value: "", label: "" };
  }

  convertToTempScale(value) {
    return value * this.deviceConfig.temperatureScale;
  }

  convertBackFromScaledValue(value) {
    return Math.round((1 / this.deviceConfig.temperatureScale) * value);
  }

  containsRunMode(runMode) {
    const isRunModeAvailable =
      this.deviceConfig != null &&
      this.deviceConfig.runModes != null &&
      this.deviceConfig.runModes[runMode] != null;

    return isRunModeAvailable;
  }

  getRunMode(runMode) {
    const isRunModeAvailable =
      this.deviceConfig != null &&
      this.deviceConfig.runModes != null &&
      this.deviceConfig.runModes[runMode] != null;

    return isRunModeAvailable ? this.deviceConfig.runModes[runMode] : "";
  }

  getRunModeByValue(value) {
    if (this.deviceConfig && this.deviceConfig.runModes) {
      for (const runMode in this.deviceConfig.runModes) {
        if (this.deviceConfig.runModes[runMode] === value) {
          return runMode;
        }
      }
    }
    return null; // If no matching runMode is found, return null
  }

  getWorkModeIconData(workMode) {
    switch (workMode) {
      case "cooling":
      case "cooling_floor_over_temp":
        return ["frost", "active"];

      case "cooling_off":
      case "cooling_off_floor_low_temp":
        return ["frost", ""];

      case "heating":
      case "heating_floor_low_temp":
      case "heating_frost_protection":
      case "heating_low":
      case "manual_on":
        return ["flame", "active"];

      case "heating":
        return ["flame", ""];

      default:
        return ["flame", ""];
    }
  }

  getFanSpeedIconData(fanSpeed) {
    switch (fanSpeed) {
      case "auto":
        return "fanAuto";

      case "low":
        return "fanLow";

      case "middle":
        return "fanMedium";

      case "high":
        return "fanHigh";

      default:
        return "fanAuto";
    }
  }
}

export default Device;
