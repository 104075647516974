import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { MutatingDots } from "react-loader-spinner";

//STYLES
import { theme } from "../utils/theme";

//COMPONENTS
import Layout from "../layout/Layout";
import Dashboard from "./main/Dashboard";
import Settings from "./main/Settings";
import Messages from "./main/Messages";
import MyHome from "./main/MyHome";
import Login from "./Login/login";
import TermostatSettings from "../components/Settings/TermostatSettings";
import RelaySettings from "../components/Settings/RelaySettings";
import ControlBoxSettings from "../components/Settings/ControlBoxSettings";
import { useAuth } from "../services/AuthContext";
import { Loader100 } from "../utils/utilsstyles";
import RollerSettings from "../components/Settings/RollerSettings";

function Main() {
  const { checkUserIsLoggedIn } = useAuth();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const verifyUser = async () => {
      const result = await checkUserIsLoggedIn();
      setIsLoggedIn(result);
      setIsLoading(false);
    };

    verifyUser();
  }, [checkUserIsLoggedIn]);

  if (isLoading) {
    // Render a loading indicator while checking auth status
    return (
      <Loader100>
        <MutatingDots
          visible={true}
          height="100"
          width="100"
          color={theme.color.base1}
          secondaryColor={theme.color.base1}
          radius="12.5"
          ariaLabel="mutating-dots-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </Loader100>
    );
  }

  if (!isLoggedIn) {
    // If user is not logged in, render the Login component
    return <Login />;
  }

  // If user is logged in, render the main application layout and routes
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/settings" element={<Settings title="Ustawienia" />} />
        <Route path="/messages" element={<Messages title="Wiadomości" />} />
        <Route path="/TermostatSettings/:id" element={<TermostatSettings />} />
        <Route path="/RelaySettings/:id" element={<RelaySettings />} />
        <Route path="/RollerSettings/:id" element={<RollerSettings />} />
        <Route
          path="/ControlBoxSettings/:id"
          element={<ControlBoxSettings />}
        />
        <Route path="/myhome" element={<MyHome title="Zarządzanie domem" />} />
      </Routes>
    </Layout>
  );
}

export default Main;
