import React from "react";
import styled from "styled-components";
import { theme } from "../../utils/theme";

// Wszystkie komponenty ikon importowane tutaj
import {
  Home,
  User,
  Settings,
  Message,
  Overview,
  Logout,
  More,
  Powerbutton,
  Temperature,
  Down,
  Up,
  Temperatureset,
  DefaultIcon,
  Engo,
  Harmonogram,
  Holiday,
  Manual,
  Frost,
  Status,
  WorkingMode,
  Hydro,
  Block,
  Light,
  Light2,
  TempMax,
  TempMin,
  Choose,
  Morning1,
  Morning2,
  Noon,
  Afternoon,
  Evening,
  Night,
  Wire,
  Wireless,
  Time,
  Counting,
  Random,
  Power,
  Remove,
  Play,
  Stop,
  Battery,
  Party,
  Empty,
  Flame,
  Sensor,
  TempFloor,
  FanHigh,
  FanMedium,
  FanLow,
  FanAuto,
  Leaf,
  Percent,
  Paus,
} from "../../assets/icons/icons"; // Upewnij się, że ścieżka jest poprawna

const iconComponents = {
  home: Home,
  user: User,
  settings: Settings,
  messages: Message,
  overview: Overview,
  logout: Logout,
  more: More,
  powerbutton: Powerbutton,
  temperature: Temperature,
  down: Down,
  up: Up,
  temperatureset: Temperatureset,
  engo: Engo,
  harmonogram: Harmonogram,
  holiday: Holiday,
  manual: Manual,
  frost: Frost,
  status: Status,
  workingmode: WorkingMode,
  hydro: Hydro,
  block: Block,
  light: Light,
  light2: Light2,
  tempmax: TempMax,
  tempmin: TempMin,
  choose: Choose,
  morning1: Morning1,
  morning2: Morning2,
  noon: Noon,
  afternoon: Afternoon,
  evening: Evening,
  night: Night,
  wire: Wire,
  wireless: Wireless,
  time: Time,
  counting: Counting,
  random: Random,
  power: Power,
  play: Play,
  stop: Stop,
  remove: Remove,
  battery: Battery,
  segment_1: Morning2,
  segment_2: Morning1,
  segment_3: Noon,
  segment_4: Afternoon,
  segment_5: Evening,
  segment_6: Night,
  party: Party,
  empty: Empty,
  flame: Flame,
  sensor: Sensor,
  tempFloor: TempFloor,
  fanHigh: FanHigh,
  fanMedium: FanMedium,
  fanLow: FanLow,
  fanAuto: FanAuto,
  leaf: Leaf,
  percent: Percent,
  paus: Paus,
};

export const Ikonka = ({ color, name, className }) => {
  const IconComponent = iconComponents[name] || DefaultIcon;
  return <IconComponent className={className} color={color} />;
};
