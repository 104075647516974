import React from "react";
//STYLES
import styled from "styled-components";
import Input from "../Forms/Input";
import { FullCardWrapper, Heading } from "../../utils/utilsstyles";
import CircIcon from "../Icons/CircIcon";
//DATA
import { useLanguage } from "../../services/LanguageContext";
import { theme } from "../../utils/theme";

const ComponentWrapper = styled.div`
  margin-bottom: 2rem;
  grid-column: 1 / span 2;
`;

const InfoLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 1.1rem;
  margin-bottom: 0.2rem;
`;

export const IconValue = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.8rem;
  max-width: 100px;

  &.special {
    h6 {
      //color: ${theme.color.base1};
    }
    h5 {
      color: ${theme.color.base1};
    }
  }

  h6 {
    color: ${theme.color.second};
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    margin-top: 5px;
    text-transform: uppercase;
    text-align: center;
  }

  h5 {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    text-transform: capitalize;
  }
`;

function ActualSettings({ device }) {
  const { translate } = useLanguage();
  const getIconBasedOnRunMode = (runMode) => {
    let icon = "";

    switch (runMode) {
      case "manual":
        icon = "manual";
        break;
      case "program":
        icon = "harmonogram";
        break;
      case "holiday":
        icon = "holiday";
        break;
      case "Frost":
        icon = "frost";
        break;
      default:
        icon = "harmonogram";
        break;
    }

    return icon;
  };

  const getWorkModeIconData = (workMode) => {
    switch (workMode) {
      case "cooling":
      case "cooling_floor_over_temp":
        return ["frost", "active"];

      case "cooling_off":
      case "cooling_off_floor_low_temp":
        return ["frost", ""];

      case "heating":
      case "heating_floor_low_temp":
      case "heating_frost_protection":
      case "heating_low":
      case "manual_on":
        return ["flame", "active"];

      case "heating":
        return ["flame", ""];

      default:
        return ["flame", ""];
    }
  };

  return (
    <ComponentWrapper>
      <FullCardWrapper>
        <Heading>
          <h3>{translate("current_device_status")}</h3>
        </Heading>

        <InfoLine>
          <IconValue className="special">
            <CircIcon
              name={getWorkModeIconData(device.getStatusValue("work_state"))[0]}
              className={
                getWorkModeIconData(device.getStatusValue("work_state"))[1]
              }
            />
            <h6>{translate("thermostatStatus")}</h6>
            <h5>
              {translate("work_state_" + device.getStatusValue("work_state"))}
            </h5>
          </IconValue>
          <IconValue>
            <CircIcon
              name={getIconBasedOnRunMode(device.getStatusValue("run_mode"))}
            />
            <h6>{translate("thermostatRunMode")}</h6>
            <h5>
              {translate("run_mode_" + device.getStatusValue("run_mode"))}
            </h5>
          </IconValue>

          {device.checkIfPropertyExists("humidity") ||
          device.checkIfPropertyExists("humidity_indoor") ? (
            <IconValue>
              <CircIcon name="hydro" />
              <h6>{translate("thermostatHumidity")}</h6>
              <h5>
                {device.getStatusValue("humidity") ||
                  device.getStatusValue("humidity_indoor")}{" "}
                %
              </h5>
            </IconValue>
          ) : null}
          {device.checkIfPropertyExists("battery_percentage") ? (
            <IconValue>
              <CircIcon name="battery" />
              <h6>{translate("thermostatBatteryStatus")}</h6>
              <h5>{device.getStatusValue("battery_percentage")} %</h5>
            </IconValue>
          ) : null}
          <IconValue>
            <CircIcon name="temperature" />
            <h6>{translate("thermostatCurrentTemperature")}</h6>
            <h5>
              {device.getStatusValueInTempScale("temp_current").toFixed(1)} °C
            </h5>
          </IconValue>
          <IconValue>
            <CircIcon name="temperatureset" />
            <h6>{translate("thermostatTempSet")}</h6>
            <h5>
              {device.getStatusValueInTempScale("temp_set").toFixed(1)} °C
            </h5>
          </IconValue>
          {device.checkIfPropertyExists("current_temp_floor") ? (
            <IconValue>
              <CircIcon name="tempFloor" />
              <h6>{translate("temp_floor")}</h6>
              <h5>
                {`${device
                  .getStatusValueInTempScale("current_temp_floor")
                  .toFixed(1)}°C`}
              </h5>
            </IconValue>
          ) : null}
          {device.checkIfPropertyExists("fan_speed_enum") &&
          device.checkIfPropertyExists("pipe_system") &&
          (device.checkIfEnumOneOf("pipe_system", [
            "Pipe2System_HeatAndFan",
            "Pipe2System_CoolAndFan",
            "Pipe2System_BothAndFan",
            "Pipe4System_BothAndFan",
          ]) ||
            (device.checkIfEnumOneOf("pipe_system", [
              "Pipe4System_UfhAndCoolWithFan",
            ]) &&
              device.getStatusValue("mode") === "cool")) &&
          device.getStatusValue("fan_control") ? (
            <IconValue>
              <CircIcon
                name={device.getFanSpeedIconData(
                  device.getStatusValue("fan_speed_enum")
                )}
              />
              <h6>{translate("fan_speed_enum")}</h6>
              <h5>
                {translate(
                  "fan_speed_enum_" + device.getStatusValue("fan_speed_enum")
                )}
              </h5>
            </IconValue>
          ) : null}
        </InfoLine>
      </FullCardWrapper>
    </ComponentWrapper>
  );
}

export default ActualSettings;
