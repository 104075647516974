import React, { useState } from "react";
//STYLES
import styled from "styled-components";

//DATA
//COMPONENTS
import { FullCardWrapper } from "../../../utils/utilsstyles";
import HarmonogramMode from "../Common/HarmonogramMode";
//FUNCTIONS
import { useLanguage } from "../../../services/LanguageContext";

const Line = styled.hr`
  margin-top: 1.5rem !important;
  margin-bottom: 1rem !important;
`;

function RollerWorkingMode({ device }) {
  const mode_program = "Program";
  const { translate } = useLanguage();

  const [activeMode, setActiveMode] = useState(
    localStorage.getItem("relayActiveMode") ?? mode_program
  );

  const updateActiveMode = (activeMode) => {
    localStorage.setItem("relayActiveMode", activeMode);
    setActiveMode(activeMode);
  };

  return (
    <FullCardWrapper>
      {activeMode === mode_program && (
        <HarmonogramMode device={device} isReadOnly={true} />
      )}
    </FullCardWrapper>
  );
}

export default RollerWorkingMode;
