import React, { useState, useEffect } from "react";
//STYLES
import styled from "styled-components";
//DATA
import { week, day_hours } from "../../../data/data";
//Utils
import { useLanguage } from "../../../services/LanguageContext";
import TimeService from "../../../services/TimeService";
import {
  updateRelayProgramEntry,
  updateRelayTimingStatus,
  addRelayProgramEntry,
  deleteRelayTiming,
} from "../../../services/ApiService";
import { RelayActionType } from "../../../services/utils/RelayActionType";

//COMPONENTS
import { Heading, ModeWrapper } from "../../../utils/utilsstyles";
import SettingTime from "./SettingTime/SettingTime";
import Modal from "../../Modals/Modal";
import Button from "../../Button/Button";
// import ButtonRounded from "../../Button/ButtonRounded";
// import { ButtonsWrapper } from "../../Termostat/WorkingModes/HarmonogramMode";
import Timer from "../../Forms/Timer";
import Switch from "../../Switch/Switch";
import RelayEntrySettingModal from "./RelayEntrySettingModal";

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  flex-direction: column;
  h3 {
    margin-bottom: 5px;
  }
`;

function HarmonogramMode({ device, isReadOnly }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentEntry, setCurrentEntry] = useState(null);
  const [entries, setEntries] = useState(
    device.deviceConfig?.programTimingConfig?.entries || []
  );

  const { translate } = useLanguage();

  useEffect(() => {
    if (
      device &&
      device.deviceConfig &&
      device.deviceConfig.programTimingConfig
    ) {
      setEntries(device.deviceConfig.programTimingConfig.entries);
    }
  }, [device]);

  useEffect(() => {
    if (
      device &&
      device.deviceConfig &&
      device.deviceConfig.programTimingConfig
    ) {
      device.deviceConfig.programTimingConfig.entries = entries;
    }
  }, [entries, device]);

  const toggleModal = () => {
    if (isModalOpen) {
      setCurrentEntry(null); // Reset the currentEntry when closing the modal
    }

    setIsModalOpen(!isModalOpen);
  };

  const handleEditEntry = (entry) => {
    setCurrentEntry(entry);
    setIsModalOpen(true);
  };

  const handleRemoved = async (entry) => {
    const updatedEntries = entries.filter((e) => e !== entry);
    setEntries(updatedEntries);
    await deleteRelayTiming(device.id, entry.groupId);
  };

  const handleOnOffChanged = async (entry, newIsOnValue) => {
    const updatedEntries = entries.map((e) =>
      e === entry ? { ...e, isOn: newIsOnValue } : e
    );
    setEntries(updatedEntries);
    await updateRelayTimingStatus(device.id, entry.groupId, newIsOnValue);
  };

  const entryChangedHandler = async (entry) => {
    const newEntries = [...entries];

    if (currentEntry != null) {
      entry.isOn = currentEntry.isOn;
      const index = newEntries.findIndex((e) => e === currentEntry);
      if (index > -1) {
        newEntries[index] = entry;
      }
    } else {
      // Check if "isOn" is set to true; if not, set it to true
      if (!entry.isOn) {
        entry.isOn = true;
      }
      newEntries.push(entry);
    }

    setEntries(newEntries);

    if (entry.groupId === null || entry.groupId === "") {
      let groupId = await addRelayProgramEntry(device.id, entry);
      entry.groupId = groupId;
    } else {
      updateRelayProgramEntry(device.id, entry);
    }

    setIsModalOpen(false);
  };

  return (
    <Wrapper>
      <Heading>
        <h3>{translate("harmonograms", "Harmonograms")}</h3>
      </Heading>
      <ModeWrapper>
        {entries.length < 1 ? (
          <p>{translate("relay_no_program_jobs")}</p>
        ) : (
          entries.map((set, index) => (
            <SettingTime
              key={index}
              entry={set}
              time={TimeService.convertToTimeFormat(
                set.time.hour,
                set.time.minute
              )}
              days={set.daysOfWeek.map((item) => translate(item))}
              isOn={set.isOn}
              isRelayOn={set.isRelayOn}
              onRemoved={handleRemoved}
              editClickedHandler={() => handleEditEntry(set)}
              onOnOffChanged={(newIsOnValue) =>
                handleOnOffChanged(set, newIsOnValue)
              }
              isReadOnly={isReadOnly}
            />
          ))
        )}
      </ModeWrapper>
      {isReadOnly ? (
        <p>{translate("cannotAddProgram")}</p>
      ) : (
        <Button variant="white" onClick={toggleModal}>
          {translate("addHarmonogram", "+ Add Harmonogram")}
        </Button>
      )}

      {isModalOpen && (
        <RelayEntrySettingModal
          week={week}
          title={
            currentEntry
              ? translate("relay_edit_program_job")
              : translate("relay_add_program_job")
          }
          intro={
            currentEntry
              ? translate("relay_editing_program_job")
              : translate("relay_adding_program_job")
          }
          initialWeekDays={currentEntry?.daysOfWeek}
          initialStartTime={currentEntry?.time ?? null}
          initialEndTime={null}
          initialIsOn={currentEntry?.isRelayOn ?? null}
          isSupportingEndTimes={false}
          toggleModal={toggleModal}
          entryChangedHandler={entryChangedHandler}
          entry={currentEntry}
          onConfirmAction={RelayActionType.PROGRAM}
          entryId={currentEntry?.groupId ?? ""}
        />
      )}
    </Wrapper>
  );
}

export default HarmonogramMode;
